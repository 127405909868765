<template>
  <!-- 登录后头部 -->
  <el-row class="headerBox"  >
    <div v-if="!flag" @click="hoverFun">
      <div class="header">
        <div class="header_top_box">
          <el-row :gutter="24" class="header_top">
            <el-col :span="10"
              ><div class="header_left">
                <span>您好,</span>
                <span class="text-overFlow">{{ msg }}</span>
                <span  v-show="BtnShow" class="effectBtn" @click="switchAccountFun">[切换账号]</span>
                <span @click="jumpLogin" class="effectBtn">退出</span>
              </div></el-col
            >
            <el-col :span="14" class="header_right">
              <div>
                <span>客服电话：4001008189</span>
                <el-divider direction="vertical"></el-divider>
                <!-- <span class="text-center GspBox"  @click="jumpToNewProject" >药品GSP图片管理系统</span> -->
                <!-- <el-divider direction="vertical"></el-divider> -->
                <!-- <span @click="jumpAbout"  class="effectBtn">关于我们</span>
                <el-divider direction="vertical"></el-divider> -->
                <span @click="jumpMy"  class="effectBtn">个人中心</span>
                <el-divider direction="vertical"></el-divider>
                <span @click="jumpMyorder"  class="effectBtn">我的订单</span>
                <el-divider direction="vertical"></el-divider>
                <span>
                  <el-popover
                    trigger="hover"
                    placement="bottom"
                    width="130"
                    ref="QR_code"
                    :visible-arrow="false"
                  >
                    <el-col
                      :span="24"
                      v-for="(item, index) in srcList"
                      :key="index"
                      :offset="index > 0 ? 0 : 0"
                    >
                      <el-card
                        class="image__preview"
                        :body-style="{ padding: '0px' }"
                      >
                        <div class="block">
                          <el-image
                            style="width: 100%; height:130px"
                            :src="item"
                            :preview-src-list="srcList"
                          >
                          </el-image>
                          <span class="demonstration">扫码下载App</span>
                        </div>
                      </el-card>
                    </el-col>
                  </el-popover>
                  <span v-popover:QR_code  class="effectBtn">手机商城</span>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-row :gutter="24" class="header_middle">
        <el-col class="header_middle_left">
          <router-link to="/home"
            ><img
              rount-link
              class="logo"
              alt="Vue logo"
              src="@/assets/dyflogo.png"
          /></router-link>
        </el-col>
        <el-col class="header_middle_center">
          <el-row :gutter="24">
            <el-col :span="18" class="search">
              <search  :isSearch="isSearch"  @searchFun="searchFun"></search>
            </el-col>
            <el-col :span="6">
              <el-badge :value="num" class="item">
                <el-button icon="el-icon-shopping-cart-2" @click="jumpCart"
                  >我的购物车</el-button
                >
              </el-badge>
            </el-col>
          </el-row>
          <!-- 热词 -->
          <el-row :gutter="24" class="hot_search">
            <span  v-for="search in searchList" :key="search.id"  @click="searchHandler(search.words) " 
             :class="{'selectActive':searchVal== search.words,'is-active':searchVal !== search.words}"
           
            >{{search.words}}</span>
          </el-row>
        </el-col>
        <el-col class="header_middle_right">
           <span class="SmartSourcingBtn"   @click="jumpSmartSourcing">智能采购</span>  
        </el-col>
      </el-row>
      <el-row :gutter="24" class="header_bottom">
        <el-col class="header_bottom_left">
          <el-popover
            v-model="visible"
            popper-class="myPopover"
            ref="meun_list"
            trigger="hover"
            placement="bottom"
            width="210"
            :visible-arrow="false"
          >
            <leftNav v-show="renderFlag" @DivhideFun="hideFun"></leftNav>
          </el-popover>
          <span v-popover:meun_list>全部商品分类</span>
        </el-col>
        <el-col class="header_bottom_middle">
          <meun></meun>
        </el-col>
      </el-row>
    </div>
    <!-- 未登录头部 -->
    <div class="login_header" v-show="flag">
      <div class="login_headerbox">
        <el-col class="login_headerbox_left">
          <img class="logo" alt="Vue logo" src="@/assets/dyflogo.png" />
        </el-col>
        <div class="login_headerbox_center">
          <span v-show="!isLheader">欢迎注册</span
          ><span v-show="isLheader">欢迎登陆</span>
        </div>
        <div class="login_headerbox_right" v-show="!isLheader">
          已有账户？ <span class="cursorBox" @click="jumpLogin">立即登录</span>
        </div>
        <div class="login_headerbox_rightBox" v-show="isLheader">
          <!-- <em class="el-icon-phone-outline"></em> -->
          <em><img src="@/assets/kefu.png" alt="客服"></em>
           <span >客服电话：400-1008189</span>
        </div>
      </div>
    </div>
  </el-row>
</template>
<style>

.myPopover {
  padding: 0 !important;
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin-top: 10px !important;
}
</style>
<style scoped lang="less">
.SmartSourcingBtn{
  cursor: pointer;
    display: inline-block;
    background-color: #FFD303;
    border-color: #FFD303;
    border-radius: 0px;
    color: #000;
    font-size: 14px;
    padding: 0 20px;
    line-height: 38px;
    margin-top: 20px;
    float: right;
}
 /deep/ .el-button{
      border-radius:0px;
  }
.cursorBox{
  cursor: pointer;
}
.el-col {
  padding: 0;
}
.GspBox{
  cursor: pointer;
  &:hover{
    color: #f33;
  }
}
// 未登录
.login_header {
  height:90px;
  .login_headerbox {
    width: 1200px;
    margin: 0 auto !important;
    height:90px;
    .login_headerbox_left {
      float: left;
      width: 210px;
      text-align: left;
      padding:21px 0;
      .logo {
        width: 100%;
        height: auto;
      }
    }
    .login_headerbox_center {
      float: left;
      padding-left: 20px !important;
      font-size: 20px;
      font-weight: bold;
      line-height:90px;
      color: #333333;
    }
    .login_headerbox_right {
      float: right;
      width: 215px;
      font-size:13px;
      color: #666666;
      text-align: right;
      margin-top:40px;
      span {
        color: #FFD303;
      }
    }
    .login_headerbox_rightBox{
      float: right;
      width:180px;
      font-size:13px;
      color: #666666;
      text-align: right;
      margin-top:34px;
      em{
        // font-size:16px;
        // font-weight: bold;
        //  color: #FFD303;
         display: inline-block;
         padding-top:5px;
         float:left;
         width:20px;
         height:20px;
         img{
           width: 100%;
           height: 100%;
         }
      }
      span {
         display: inline-block;
         height: 30px;
          line-height: 30px;
        color: #666666;
      }
    }
  }
}

// 兼容1280屏幕
// @media screen and (max-width: 1280px) {
//   .login_headerbox {
//     width: 990px !important;
//   }
//   .header_top {
//     width: 990px !important;
//   }
//   .header_middle {
//     width: 990px !important;
//   }
//   .header_bottom {
//     width: 990px !important;
//   }
//   /deep/.el-menu-item {
//     padding: 0 10px !important;
//   }
// }

// 登录后
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.effectBtn{
  cursor: pointer;
  &:hover{
    color: #FFD303;
  }
}
.headerBox{
  position: fixed;
    top: 0;
    background: #f6f6f6;
    width: 100%;
    left: 0;
    z-index: 999;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1)
}
.header {
  background: #f6f6f6;
  .header_top_box {
    background: #f6f6f6;
    .header_top {
      width: 1200px;
      margin: 0 auto !important;
      .header_left {
        font-size: 12px;
        text-align: left;
        line-height: 34px;
        & > span {
          padding-left: 10px;
        }
        & > span:first-child,
        & > span:nth-child(2) {
          padding-left: 0px;
        }
        .quickRegister {
          color: #FFD303;
        }
      }
      .header_right {
        text-align: right;
        line-height: 34px;
        font-size: 12px;
        padding: 0 !important;
      }
    }
  }
}
.header_middle {
  width: 1200px;
  margin: 0 auto !important;
  .header_middle_left {
    width: 210px;
    text-align: left;
    padding:20px 0;
    .logo {
      width: 100%;
      height: auto;
    }
  }

  .header_middle_center {
    width: calc(100% - 425px);
    padding-top: 10px;
    padding-left: 32px !important;
    .search {
      // width: 560px;
    }
  }
  .header_middle_right {
    width: 215px;
  }
  .hot_search {
    padding-left:22px;
    text-align: left;

    span {
      font-size: 12px;
      margin-right: 8px;
      color: #999999;
      &:hover{
        cursor: pointer;
        font-weight: bold;
        color: #FFD303;
      }
       &.selectActive{
        font-weight: bold;
        color: #FFD303;
      }
    }
  }
}
.header_bottom {
  width: 1200px;
  margin: 0 auto !important;
  .header_bottom_left {
    width: 210px;
    height: 40px;
    line-height: 40px;
    background: #FFD303;
    color: #000;
    text-align: center;
  }
  .header_bottom_middle {
    width: calc(100% - 210px);
     cursor: pointer;
  }
}

span.demonstration {
  text-align: center;
  line-height: 24px;
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}
.el-image {
  width: 100%;
  display: block;
}
.el-badge {
  margin-top: 10px;
  font-size: 12px;
}
.el-badge__content {
  background-color: #ff3333 !important ;
}
.el-button {
  border: 1px solid #ff3333;
    color: #ff3333;
    font-size: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>

<script>
import { GetMemberStore } from "api/member.js";
import { HotWords } from "api/product.js";
const search = () => import("components/index/search.vue");
const meun = () => import("components/index/meun.vue");
const leftNav = () => import("components/index/leftNav.vue");
// import IosCode from "assets/3Aios_down.png";
// import AZCode from "assets/3Aaz_down.png";
import Code from "assets/3AApp_down.png";
import { CartCount } from "api/product.js";      
import { mapState, mapGetters,mapActions,mapMutations } from "vuex";
import Cookies from 'js-cookie';
export default {
  name: "Header",
  inject:['reload'],
  components: {
    search,
    meun,
    leftNav,
  },
  data() {
    return {
        BtnShow: false,
      msg: "",
      visible: false,
      renderFlag: "false",
       srcList: [Code],
      // srcList: [IosCode,AZCode],
      searchList:[],
      searchVal:'',
      isSearch:false
    };
  },
  computed: {
    //计算属性可实时更新购物车数量
    ...mapState({
      flag: (state) => state.flag,
      num: (state) => state.carGoodsNum,
      isLogin: (state) => state.isLogin,
      isLheader: (state) => state.isLheader,
    }),
     ...mapGetters([
            'memberNameFun',
        ])
  },
  //监听执行
  watch: {
    "$route.path": function(newVal) {
      if(newVal!="/cart"){
         this.isloadFun()
      }
    },
  //  "$route.path": function(newVal) {
  //     if(newVal=='/home'){
  //       // 获取店铺信息
  //        this.msg = localStorage.getItem("memberName")
  //     }
  //   },
    "msg":function(){
      this.isloadFun()
    },
     "memberNameFun": function (newValue) {
        if(newValue) {
          this.msg = localStorage.getItem("memberName");    //赋值后可监听上面msg
          // this.memberName(localStorage.getItem("memberName"))
           GetMemberStore().then((res) => {
              if(res.data.data.length>1){
                    this.BtnShow=true
              }else{
                this.BtnShow=false
              }
          });
          const parmesData = {
                  //暂无
                  current: 1,
                  size:10
                };
          HotWords(parmesData).then(res=>{
            console.log('sdghjkl;',res.data.data.records)
            this.searchList=res.data.data.records
          })
        }
    }
  },
  created() {

    
  },
  mounted(){
     this.msg = localStorage.getItem("memberName");
  },
  methods: {
    searchFun(isSearch){
        this.isSearch=isSearch
    },
    hoverFun(){
      if(this.isSearch){
        this.isSearch=false
      }
    },
      //热词点击
      searchHandler(HotWords) {
       localStorage.setItem("searchVal", HotWords);
        this.$router.push({name:"ProductType",query:{type:11,seachVal:HotWords}})
          this.searchVal=localStorage.getItem("searchVal")

    },
      switchAccountFun(){
       this.$router.push({ path: "/my/myInvoice" });
    },
    jumpToNewProject: function () {
        var memberName=localStorage.getItem("memberName");
        let refresh_token=localStorage.getItem("refresh_token")
        //expires:  cookie有效时间   path: cookie所在目录 设置path: '/projectName'指定项目名下'/projectName'使用
        // domain: cookie所在的域，默认为请求地址
        //  Cookies.set('memberName', memberName, { expires: 1000, path: '', domain: 'http://localhost:8080/' })
        let domainStr=document.domain.split('.').slice(-2).join('.')
       
        // let JumpUrl='http://gsp.'+domainStr+'/#/login'
        // let JumpUrl='http://'+window.location.hostname+':8003/#/login'
        if(domainStr=='3akx.cn'||domainStr=='3akx.com'){
            Cookies.set('memberName',memberName,{path:'/',domain:'3akx.cn'});
          Cookies.set('refresh_token',refresh_token,{path:'/',domain:'3akx.cn'});
          window.open("https://gsp.3akx.cn/");
        }else{
           Cookies.set('memberName',memberName);
            Cookies.set('refresh_token',refresh_token);
            let JumpUrl='http://'+window.location.hostname+':8003/#/login'
            window.open(JumpUrl); // 这个地址是你要跳转的项目url
        }
    },
    isloadFun() {
      if (!this.flag) {
        if(this.$route.path!="/cart"){
              //首次加载初始化购物车数量
              // this.getGoods();
              if(localStorage.getItem("token")){
                  CartCount().then(res=>{
                    if(res.data!=undefined){
                      let sumdata={
                        initsum:Number(res.data),
                      }
                      this.setCarGoodsNum(sumdata)
                    }else{
                      let sumdata={
                        initsum:0,
                      }
                      this.setCarGoodsNum(sumdata)
                    }
                    // if(res.data.records!=undefined){
                    //   let result=res.data.records
                    //   let sum = result.reduce(function(prev, cur) {
                    //       return cur.quantity + prev;
                    //   }, 0);
                    //   let sumdata={
                    //     initsum:sum,
                    //   }
                    //   this.setCarGoodsNum(sumdata)
                    // }else{
                    //   let sumdata={
                    //     initsum:0,
                    //   }
                    //   this.setCarGoodsNum(sumdata)
                    // }
                  })
              }
        }
      }
    },
    hideFun() {
      this.visible = false;
    },
    ...mapActions({
      // getGoods:"getGoods",
      getmemberName:"getmemberName"
    }),
    ...mapMutations({
      changeFlag: "changeFlag",
      changeBoolean: "changeBoolean",
      setCarGoodsNum: "setCarGoodsNum",
    }),
    jumpSmartSourcing(){
      this.$router.push({name:"SmartSourcing"})
    },
    jumpMy() {
      this.$router.push({ name: "MyAccount" });
    },
    jumpMyorder() {
      this.$router.push({ name: "MyOrder" });
    },
    jumpAbout() {
      this.$router.push({ name: "About" });
    },
    jumpLogin() {
        Cookies.remove('memberName');
      this.$router.push({ name: "Login" });
    },
    jumpRegister() {
      this.$router.push({ name: "Register" });
    },
    jumpCart() {
      this.$router.push({ name: "Cart" });
    },
  },
};
</script>
